import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Flex,
  NumberInput,
} from '@chakra-ui/react'
import { StarIcon } from '@chakra-ui/icons'

const LeaderboardTable = props => {
  // fetch all scores (queried at build time)
  const data = useStaticQuery(graphql`
    query leaderboardQuery {
      allScore {
        nodes {
          player {
            name
            id
          }
          id
          scores
          highscore_type {
            name
            id
            type
            field_name
          }
        }
      }
    }
  `)

  // only use the data of specific highscore type (set dynamically based on props)
  let leaderboardData = data.allScore.nodes.filter(function (entry) {
    return entry.highscore_type.id === props.highscoreType.id
  })

  let trainingsCountData = data.allScore.nodes.filter(function (entry) {
    return entry.highscore_type.id === "AxbfVR4WgGWo8n2EAWb8"
  })

  console.log(trainingsCountData)

  // define color lookup for leaderboard ranks (1-3) - not used for now
  //const colorLookup = ['yellow.400', 'gray.300', 'bronze.100']

  // helper function to set the rank of each data entry
  function getDataWithRanks(data) {
    data.forEach((entry, index) => {
      if (index === 0) {
        entry.rank = 1
      } else if (entry.value === data[index - 1].value) {
        entry.rank = data[index - 1].rank
      } else {
        entry.rank = index + 1
      }
    })
    return data
  }

  const TableHead = () => (
    <Thead>
      <Tr>
        {props.highscoreType.type !== 'log' && <Th>Rang</Th>}
        <Th>Spieler</Th>
        <Th isNumeric>{props.highscoreType.field_name}</Th>
        {props.highscoreType.name === "Tagessiege" && <Th isNumeric>Quote</Th>}
      </Tr>
    </Thead>
  )

  function TableBody() {
    let leaderboardType = props.highscoreType.type // [min, max, count, log]
    let highscoreType = props.highscoreType.name
    let scope_index = parseInt(props.scope.id)
    let tableBodyData = []

    // type = max
    if (leaderboardType === 'max') {
      leaderboardData.forEach(entry => {
        let val = 0
        if (scope_index < 0) {
          val = Math.max(...entry.scores)
        } else if (typeof entry.scores[scope_index] !== 'undefined') {
          val = parseFloat(entry.scores[scope_index])
        }

        tableBodyData.push({
          id: entry.player.id,
          player: entry.player.name,
          value: val,
        })
      })
      tableBodyData.sort((a, b) => b.value - a.value)
      getDataWithRanks(tableBodyData)
      // type = min
    } else if (leaderboardType === 'min') {
      leaderboardData.forEach(entry => {
        let val = '0'
        if (scope_index < 0) {
          var entry_scores = []
          entry.scores.forEach(score => {
            if (score !== '0') {
              entry_scores.push(score)
            }
          })
          val = entry_scores.length > 0 ? Math.min(...entry_scores) : '0'
        } else if (typeof entry.scores[scope_index] !== 'undefined') {
          val = entry.scores[scope_index]
        }

        if (val !== '0') {
          tableBodyData.push({
            id: entry.player.id,
            player: entry.player.name,
            value: val,
          })
        }
      })
      tableBodyData.sort((a, b) => a.value - b.value)
      getDataWithRanks(tableBodyData)
      // type = count
    } else if (leaderboardType === 'count') {
      leaderboardData.forEach(entry => {
        let val = 0
        let trainingsCount = 0
        let trainingsCountEntry;

        trainingsCountData.forEach(countEntry => {
          if (countEntry.player.id === entry.player.id) {
            trainingsCountEntry = countEntry;
          }
        });

        if (scope_index < 0) {
          entry.scores.forEach(score => {
            val += parseInt(score)
          })
          if(typeof trainingsCountEntry !== 'undefined') {
            trainingsCountEntry.scores.forEach(score => {
              trainingsCount += parseInt(score)
            })
          }
        } else if (typeof entry.scores[scope_index] !== 'undefined') {
          val = entry.scores[scope_index]
          if(typeof trainingsCountEntry !== 'undefined' && typeof trainingsCountEntry.scores[scope_index] !== 'undefined') {
            trainingsCount = trainingsCountEntry.scores[scope_index]
          }
        }

        tableBodyData.push({
          id: entry.player.id,
          player: entry.player.name,
          value: val,
          trainingsCount: trainingsCount
        })
      })
      tableBodyData.sort((a, b) => b.value - a.value)
      getDataWithRanks(tableBodyData)
      // type = log
    } else if (leaderboardType === 'log') {
      leaderboardData.forEach(entry => {
        entry.scores.forEach(score => {
          let date = new Date(score)
          let yyyy = date.getFullYear()
          let mm = date.getMonth() + 1
          let dd = date.getDate()
          if (dd < 10) dd = '0' + dd
          if (mm < 10) mm = '0' + mm
          const formattedDate = dd + '.' + mm + '.' + yyyy
          tableBodyData.push({
            id: entry.player.id,
            player: entry.player.name,
            value: formattedDate,
          })
        })
      })
      tableBodyData.sort((a, b) => {
        const dateA = new Date(a.value.split('.').reverse().join('-'));
        const dateB = new Date(b.value.split('.').reverse().join('-'));
        return dateB - dateA;
      });
      console.log(tableBodyData)
    }

    return (
      <Tbody>
        {tableBodyData.map((dataEntry, index) => (
          <Tr key={dataEntry.id}>
            {
              // display rank only for data not of type 'log'
              leaderboardType !== 'log' && (
                <Td border={index === tableBodyData.length - 1 && 'none'}>
                  <Flex alignItems="center">
                    <StarIcon
                      color={
                        dataEntry.rank === 1 ? 'yellow.400' : 'transparent'
                      }
                      marginRight="10px"
                    />
                    {dataEntry.rank}
                  </Flex>
                </Td>
              )
            }
            <Td border={index === tableBodyData.length - 1 && 'none'}>
              {dataEntry.player}
            </Td>
            <Td border={index === tableBodyData.length - 1 && 'none'} isNumeric>
              {highscoreType === '501 - Average'
                ? dataEntry.value.toFixed(2)
                : dataEntry.value}
            </Td>
            {highscoreType === "Tagessiege" && 
              <Td isNumeric>
                {dataEntry.trainingsCount == 0
                  ? "0.00"
                  : `${parseFloat(dataEntry.value / dataEntry.trainingsCount).toFixed(2)}`
                }
              </Td>
            }
          </Tr>
        ))}
      </Tbody>
    )
  }

  return (
    <Box borderWidth="1px" borderRadius="lg">
      {leaderboardData.length > 0 ? (
        <TableContainer>
          <Table variant="simple">
            <TableHead />
            <TableBody />
          </Table>
        </TableContainer>
      ) : (
        <h1 style={{ padding: '1rem' }}>
          Für diesen Spielmodus liegen noch keine Daten vor.
        </h1>
      )}
    </Box>
  )
}

export default LeaderboardTable
